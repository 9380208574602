import React, { useEffect } from 'react';
import ContactAutocomplete from './ContactAutocomplete';
import { Alert, Button, Stack, TextField } from '@mui/material';

const Contact = ({contact, setContact, userAlreadyExists, loaded}) => {
    const [newContact, setNewContact] = React.useState(contact);
    const [createContact, setCreateContact] = React.useState(contact.create);
    loaded();
    return (
        <Stack spacing={2} style={{width: "100%"}}>
            
            {createContact && <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            {userAlreadyExists &&
                <Alert severity="error" >El usuario ya existe, por favor cambie a seleccionar usuario</Alert>
            }
                <TextField fullWidth id="standard-basic" label="Nombre" variant="standard"
                    defaultValue={contact.firstname}
                    onChange={(event) => {
                        var newData = { firstname: event.target.value, lastname: newContact.lastname, email: newContact.email, phone_number: newContact.phone_number, create: true, options: [] };
                        setNewContact(newData);
                        setContact(newData);
                }} />
                <TextField fullWidth id="standard-basic" label="Apellidos" variant="standard"
                    defaultValue={contact.lastname}
                    onChange={(event) => {
                        var newData = { firstname: newContact.firstname, lastname: event.target.value, email: newContact.email, phone_number: newContact.phone_number, create: true, options: [] };
                        setNewContact(newData);
                        setContact(newData);
                    }} />
                <TextField fullWidth id="standard-basic" label="Email" variant="standard"
                    defaultValue={contact.email}
                    type="email"
                    onChange={(event) => {
                        var newData = { firstname: newContact.firstname, lastname: newContact.lastname, email: event.target.value, phone_number: newContact.phone_number, create: true, options: [] };
                        setNewContact(newData);
                        setContact(newData);
                    }} />
                <TextField fullWidth id="standard-basic" label="Teléfono" variant="standard"
                    defaultValue={contact.phone_number}
                    onChange={(event) => {
                        var newData = { firstname: newContact.firstname, lastname: newContact.lastname, email: newContact.email, phone_number: event.target.value, create: true, options: [] };
                        setNewContact(newData);
                        setContact(newData);
                    }} />
            </Stack>}
           {!createContact && <ContactAutocomplete opts={contact.options} contact={contact} setContact={setContact} />}
           <Button variant="contained" color="primary" onClick={() => { setCreateContact(!createContact) }}>
                {createContact && "Cambiar a seleccionar contacto"}
                {!createContact && "Cambiar a crear contacto"}
            </Button>
        </Stack>
    );
}

export default Contact;