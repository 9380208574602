import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import Api from '../../Services/Api';
import * as Sentry from "@sentry/browser";

const Company = ({company, selectCompany, setCompany, loaded}) => {
    const [createCompany, setCreateCompany] = React.useState(company.create);
    const [newCompany, setNewCompany] = React.useState(company);
    const [options, setOptions] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState(undefined);

    useState(() => {
        if(company.options != undefined) {
            setOptions(company.options)
            setDefaultValue(company.options.filter((option) => option.id == company.id)[0])            
        } else if(company.options && company.options.length > 0) {
            setDefaultValue(company.options[0]);
        }
        loaded();
    }, [])

    const onInputChangeCompany = (event, value, reason) => {
        if (value) {
          Api.fetchCompanies(value).then((result) => {
            setOptions(result.options)
          }).catch((error) => {
            Sentry.captureException(error);
            console.log(error)
          })
        } else {
          setOptions([]);
        }
      };

    return (
        <Stack spacing={2} style={{width: "100%"}}>
            {createCompany &&
            <Stack spacing={2} fullWidth style={{ width: "100%" }}>
                <TextField fullWidth id="standard-basic" label="Nombre de la compañía" defaultValue={newCompany.name} variant="standard" onChange={(event) => {
                    var newData = {name: event.target.value, domain: newCompany.domain, create: true};
                    setNewCompany(newData);
                    setCompany(newData);
                }} />
                <TextField fullWidth id="standard-basic" label="Url compañia" variant="standard" defaultValue={newCompany.domain} onChange={(event) => {
                    var newData = {name: newCompany.name, domain: event.target.value, create: true};
                    setNewCompany(newData);
                    setCompany(newData);
                }} />
            </Stack>
            }

            {!createCompany &&
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ width: "100%" }}
                defaultValue={defaultValue}
                onInputChange={onInputChangeCompany}
                onChange={(event, newValue) => {
                    console.log("Setting company id", newValue)
                    if (newValue != null && newValue.id != null) {
                        selectCompany({id: newValue.id, name: newValue.name, domain: newValue.domain, create: false, options: options});
                    }
                }}
                options={options.sort((a, b) => {
                    if (a.name != null && b.name != null) return -b.name.localeCompare(a.name)
                })}
                groupBy={(option) => {
                    if (option.name != null) return Array.from(option.name)[0].toUpperCase();
                }
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Selecciona una compañia" />}
                renderOption={(props, option) => (
                    <li key={option.id} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.name}
                    </li>
                )}
            />
            }   
            <Button variant="contained" color="primary" onClick={() => { setCreateCompany(!createCompany) }}>
                {createCompany && "Cambiar a seleccionar compañia"}
                {!createCompany && "Cambiar a crear compañia"}
            </Button>
        </Stack>
    );
}

export default Company;